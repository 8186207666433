import { Color, DoubleSide, Group, Mesh, ShaderMaterial } from 'three';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import { Font } from 'three/addons/loaders/FontLoader.js';

import Aventa from 'assets/fonts/Aventa/Aventa-Thin.json';

const font = new Font(Aventa);

const vertex = `
  uniform vec3 bboxMin;
  uniform vec3 bboxMax;

  varying vec3 vUv;

  void main() {
    vUv.z = (position.z - bboxMin.z) / (bboxMax.z - bboxMin.z);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

const fragment = `
  uniform vec3 color1;
  uniform vec3 color2;
  uniform vec3 color3;

  varying vec3 vUv;

  void main() {
    gl_FragColor = vec4(mix(mix(color1, color2, vUv.z), mix(color2, color3, vUv.z), vUv.z), 1.0);
  }
`;

class Text extends Group {
  isText = true;

  mesh;

  constructor(data = {}) {
    super();

    this.data = data;

    const geometry = new TextGeometry(data.text, {
      bevelEnabled: false,
      curveSegments: 100,
      height: 1.2,
      size: 4,
      font
    });

    geometry.computeBoundingBox();

    const material = new ShaderMaterial({
      uniforms: {
        color1: { value: new Color(0x000000) },
        color2: { value: new Color(0x000000) },
        color3: { value: new Color(0x5a5a5a) },
        bboxMin: { value: geometry.boundingBox.min },
        bboxMax: { value: geometry.boundingBox.max }
      },
      fragmentShader: fragment,
      vertexShader: vertex,
      side: DoubleSide
    });

    this.mesh = new Mesh(geometry, material);

    this.position.copy(data.position);
    this.rotation.copy(data.rotation);

    this.add(this.mesh);
  }
}

export default Text;
