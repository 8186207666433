import project1 from 'assets/img/project-1.webp';
import project2 from 'assets/img/project-2.webp';
import project3 from 'assets/img/project-3.webp';
import project4 from 'assets/img/project-4.webp';
import project5 from 'assets/img/project-5.webp';
import project6 from 'assets/img/project-6.webp';
import project7 from 'assets/img/project-7.webp';
import project8 from 'assets/img/project-8.webp';
import project9 from 'assets/img/project-9.webp';
import project10 from 'assets/img/project-10.webp';
import project11 from 'assets/img/project-11.webp';

export const projects = [
  {
    id: 11,
    name: 'Rivox',
    url: 'https://rivox.ru/',
    description: '',
    preview: project11
  },
  {
    id: 10,
    name: 'ATM Virtual',
    url: 'https://atmvirtual.pl/',
    description: '',
    preview: project10
  },
  {
    id: 9,
    name: 'HART Estate',
    url: 'https://hart-estate.ru/',
    description: '',
    preview: project9
  },
  {
    id: 8,
    name: 'Prostokvashino',
    url: 'https://ferma.prostokvashino.ru/',
    description: '',
    preview: project8
  },
  {
    id: 7,
    name: 'Apogey',
    url: 'https://apogey-mebel.ru/',
    description: '',
    preview: project7
  },
  {
    id: 6,
    name: 'White Frame',
    url: 'https://whiteframe.ru/',
    description: '',
    preview: project6
  },
  {
    id: 5,
    name: 'AK BARS RETRO CARS',
    url: 'https://akbarsretrocars.ru/',
    description: '',
    preview: project5
  },
  {
    id: 4,
    name: 'Graver-Laser',
    url: 'https://graver-laser.com/',
    description: '',
    preview: project4
  },
  {
    id: 3,
    name: 'Skydiving',
    url: null,
    description: '',
    preview: project3
  },
  {
    id: 2,
    name: 'Godji Game',
    url: null,
    description: '',
    preview: project2
  },
  {
    id: 1,
    name: 'Strizhman',
    url: 'https://strizhman.ru/',
    description: '',
    preview: project1
  }
];
